<template>
    <div>
  
      <!-- {{ get_plans }} -->
      <b-card no-body>
        <div class="m-2">
          <b-row class="align-items-center">
            <b-col cols="3" md="3" class="pr-0">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                  @input="filterAppUser" />
              </div>
            </b-col>
  
            <!-- Plan Select -->
            <b-col cols="3" md="3" class="pl-0 pr-0">
              <v-select v-model="selectedPlan" :clearable="true" label="name" :options="plansWithCountry"
                :reduce="option => option" @input="filterByPlan(selectedPlan)" placeholder="Select Plan"
                :style="CustomFontSize">
              </v-select>
            </b-col>
            <b-col cols="3" md="3" class="pl-0 pr-0">
                <v-select
                    class="ml-1"
                    v-model="selectedPlatform"
                    multiple
                    :options="platformsData"
                    :filterable="false" 
                    placeholder="Select Platform"
                    @input="filterByPlatform()"
                    :style="CustomFontSize"
                >
                </v-select>
  
           </b-col>

           <b-col cols="3" md="3" class="pl-0 pr-0">
            <v-select
                class="ml-1"
                v-model="selectedType"
                :options="releaseTypes"
                :filterable="false" 
                placeholder="Select Subscription Type"
                @input="filterByOrder()"
                :style="CustomFontSize"
            >
            </v-select>

       </b-col>
          </b-row>
  
  
        </div>
        <b-overlay :show="show" rounded="sm">
          <b-card no-body>
            <b-table-simple hover caption-top responsive class="rounded-bottom mb-0" :key="sidebarkey">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>#</b-th>
                  <b-th>Order Id</b-th>
                  <b-th>Date</b-th>
                  <b-th>Platform</b-th>
                  <b-th>Profile</b-th>
                  <b-th>Current Plan</b-th>
                  <b-th>Status</b-th>
                  <b-th>Renewable</b-th>
                  <b-th>Payment Type</b-th>
                  <b-th>Payment Method</b-th>
                  <b-th>Expiry Date</b-th>

                </b-tr>
              </b-thead>
              <b-tbody>
          
                
                    <template v-for="(users_data, index) in table_data">
                      <b-tr :key="'row-' + index">
                        <b-th>
                          {{index+1}}
                        </b-th>
                        <b-th>
                          <span v-if="users_data && !users_data.offline">
                            {{ users_data.subscription.transactionId }}
                          </span>
                          <span v-else>
                            {{ users_data.subscription._id}}
                          </span>
                        </b-th>
                        <b-th>
                         <span>
                          {{getDateISO(users_data.subscription.createdAt)  }}
                         </span>
                        </b-th>
                        <b-th>
                         <span>
                          {{ users_data.subscription.paymentMethod || 'N/A'}}
                         </span>
                        </b-th>
                        <b-th>
                          {{ users_data.serialNumber }}-{{ users_data.name }}
                        </b-th>
                        <b-th>
                          {{ users_data.current_plan.name }}
                        </b-th>
                        <b-th>
                          <span v-if="users_data.subscription.subscriptionStatus && users_data.subscription.subscriptionExpiredDateTime">
                            {{ ['Renew','Active','active'].includes(users_data.subscription.subscriptionStatus) && !checkSubscriptionExpired(users_data.subscriptionExpiredDateTime)  ? 'Active' : users_data.subscription.subscriptionStatus}}
                          </span>
                          <span v-else>
                             Expired
                          </span>
                        </b-th>
                        <b-th>
                          <span v-if="users_data.subscription.subscriptionExpiredDateTime">
                            {{ ['Paused','Expired','Cancelled'].includes(users_data.subscription.subscriptionStatus)  ? false : true}}
                          </span>
                          <span v-else>
                             false
                          </span>
                        </b-th>
                        <b-th>
                          <span v-if="users_data && users_data.offline">
                            Offline
                          </span>
                          <span v-else>
                              Online
                          </span>
                         </b-th>
                         <b-th>
                          <span v-if="users_data && users_data.paymentType">
                            {{ users_data.paymentType }}
                          </span>
                          <span v-else>
                              N/A
                          </span>
                         </b-th>
                         <b-th>
                          {{ users_data.subscription.subscriptionExpiredDateTime ? getDate(users_data.subscriptionExpiredDateTime) : 'N/A' }}
                        </b-th>

                    </b-tr>

                  </template>

              </b-tbody>
            </b-table-simple>
            <!-- <div class="mx-2 mt-1">
              <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
                <div class="dataTables_info mt-1">Showing {{ table_data.from }} to {{ table_data.to }} of {{
                  table_data.total }}
                  entries
                </div>
                <b-pagination v-model="currentPage" :total-rows="table_data.total" :limit=7 :per-page="per_page"
                  @change="getTableData" class="mt-1 mb-0">
                </b-pagination>
  
              </div>
            </div> -->
          </b-card>
        </b-overlay>
        <b-modal centered v-model="showUserDetailsModal" title="User Details">
          <div>
            <p>Email/Username: {{ selectedProfileEmail }}</p>
            <p>ID: {{ selectedId }}</p>
          </div>
        </b-modal>
        <b-modal centered size="lg" v-model="showInstallReferrerSourceModal" title="Install Referrer Detail" hide-footer>
          <div>
            <div v-if="InstallReferrerSourceData.installReferrer">
              <table class="table table-bordered">
                <tbody>
                  <tr v-for="(item, key) in Helpers.jsonToArray(InstallReferrerSourceData.installReferrer)" :key="key">
                    <th>{{ item.key }}</th>
                    <td>
                      <div style="word-break: break-all;">
                        {{ item.value }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-modal>
        <b-modal title="Change Password" centered v-model="isChangePasswordModel" @cancel.prevent="cancelChangePassword"
          @ok.prevent="submitChangePassword" @close.prevent="cancelChangePassword">
          <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{
            errorMsg }}</b-alert>
          <p class="mb-0 ">Password</p>
          <b-form-input class="mb-1" v-model="password" placeholder="Password" type="text" />
          <p class="mb-0 ">Confirm Password</p>
          <b-form-input class="mb-1" v-model="confirmPassword" placeholder="Confirm Password" type="text" />
          <b-form-group label="Please enter a description (at least 50 characters):">
            <b-form-textarea v-model="description" rows="6"></b-form-textarea>
          </b-form-group>
          <div>
            <span>{{ description.length }}</span> / 20 characters
          </div>
        </b-modal>
  
        <b-modal title="Provide Description" centered v-model="showdiscriptionModal" @cancel.prevent="cancelModal"
          @ok.prevent="submitDescription" @close.prevent="cancelModal">
          <b-form-group label="Please enter a description (at least 50 characters):">
            <b-form-textarea v-model="description" rows="6"></b-form-textarea>
          </b-form-group>
          <div class="alert alert-danger" v-if="descriptionError">
            Please enter at least 50 characters.
          </div>
          <div>
            <span>{{ description.length }}</span> / 50 characters
          </div>
        </b-modal>
      </b-card>
      <b-modal
      id="subscription-modal"
      no-close-on-backdrop
      hide-footer
      size="lg"
      centered
      title="Subscription Details"
      v-b-modal.subscription-modal
      @hidden="resetSubscription">
      <b-tabs style="height: 430px; overflow: scroll">
        <b-tab active>
          <template #title>
            <span>Details</span>
          </template>
  
          <div :key="sidebarkey">
              <div v-if="isLoadingSubscription" class="d-flex justify-content-center align-items-center">
                <div class="spinner-border" role="status">
                </div>
              </div>
              
                <div v-if="!isLoadingSubscription && subscriptionInfo && subscriptionInfo.orderId && !subscriptionInfo.reason">
                  <p><strong>Order ID:</strong> {{ subscriptionInfo.orderId }}</p>
                  <p><strong>Status:</strong> {{ subscriptionInfo.status }}</p>
                  <p><strong>Start Date:</strong> {{ subscriptionInfo.startTimeMillis ? getDate(subscriptionInfo.startTimeMillis) : 'N/A' }}</p>
                  <p><strong>Expiry Date:</strong> {{ subscriptionInfo.expiryTimeMillis ? getDate(subscriptionInfo.expiryTimeMillis) : 'N/A' }}</p>
                  <p><strong>Renewable:</strong> {{ subscriptionInfo.autoRenewing }}</p>
                  <p><strong>Country Code:</strong> {{ subscriptionInfo.countryCode }}</p>
                  <p><strong>Amount Paid:</strong> {{ convertMicrosToBaseCurrency(subscriptionInfo.priceAmountMicros) + ' ' + subscriptionInfo.priceCurrencyCode }}</p>
                </div>
                <div v-if="!isLoadingSubscription && subscriptionInfo  && subscriptionInfo.reason">
                  <p><strong>Status:</strong> {{ subscriptionInfo.status }}</p>
                  <p><strong>Reason:</strong> {{ subscriptionInfo.reason}}</p>
                  <p><strong>Message:</strong> {{ subscriptionInfo.message }}</p>
          
                </div>
                <div>
                  <p v-if="subscriptionInfo && subscriptionInfo.subscriptionStatus"><strong>Status:</strong> {{ subscriptionInfo.subscriptionStatus || "" }}</p>
                  <p v-if="subscriptionInfo && subscriptionInfo.paymentType"><strong>Payment Type:</strong> {{ subscriptionInfo.paymentType }}</p>
                  <p><strong>Current Plan:</strong> {{subscriptionInfo && subscriptionInfo.subscriptionStatus =='Active' ? subscriptionInfo.currentPlanName : 'no active plan' }}</p>
          
                </div>
          </div>
        </b-tab>
        <b-tab active>
          <template #title>
            <span> Payments </span>
          </template>
  
          <div :key="sidebarkey">
            <b-table-simple hover caption-top responsive class="rounded-bottom mb-0" :key="sidebarkey">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>Product ID</b-th>
                  <b-th>Plan Name.</b-th>
                  <b-th>Price</b-th>
                  <b-th>Platform</b-th>
                  <b-th>Payment Type</b-th>
                  <b-th>Status</b-th>
                  <b-th>Expiry Date</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(users_data, index) in selected_user.payments">
                  <b-tr :key="'row-' + index">
                    <b-th>
                      <div class="date-details-container">
                        <span>
                          {{ users_data.productId }}
                        </span>
                      </div>
                    </b-th>
                    <b-th>
                      <div class="date-details-container">
                        <span>
  
                          {{ users_data.plan }}
                        </span>
                      </div>
                    </b-th>
                    <b-th>
                      <div class="date-details-container">
                        {{ users_data.price }}
                      </div>
                    </b-th>
                    <b-th>
                      <div class="date-details-container">
                        {{ users_data.paymentMethod }}
                      </div>
                    </b-th>
                    <b-th>
                      <div class="date-details-container">
                        {{ users_data.paymentType }}
                      </div>
                    </b-th>
                    <b-th>
                      <div class="date-details-container">
                        {{ users_data.subscriptionStatus }}
                      </div>
                    </b-th>
                     <b-th>
                      <div class="date-details-container">
                        {{users_data.subscriptionExpiredDateTime ? formatMilli(users_data.subscriptionExpiredDateTime) : 'N/A' }}
                      </div>
                    </b-th>
                    </b-tr>
                    </template>
              </b-tbody>
              </b-table-simple>
          </div>
        </b-tab>
        </b-tabs>
  
  
  
  </b-modal>
  <b-modal ref="popup-modal" v-b-modal.delete-modal id="delete-modal" title="Cancel Subscription" hide-footer>
    <p>Are you sure you want to cancel the {{ selected_user.name}} subscription ?</p>
    <div class="mt-4 d-flex justify-content-end">
      <b-button variant="danger" style="cursor: pointer;" @click="handleDelete">Confirm</b-button>
      <b-button variant="primary" style="cursor: pointer;" class="ml-2" @click="$bvModal.hide('delete-modal')">
        Cancel
      </b-button>
    </div>
  </b-modal>
      <UserCurrentPlan :get_currencies="get_currencies" :manage="true" :get_plans="get_plans" :get_billing_cycles="get_billing_cycles"
        @emitPlanUpdated="getTableData" :selected_user="selected_user" @close="selected_user =  null,getTableData(1)" ></UserCurrentPlan>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BFormCheckbox,
    BTableSimple,
    BFormInput,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BOverlay,
    BTfoot,
    BFormTextarea,
    BPagination,
    BAlert,
    BFormGroup,
    BTabs,
    BTab
  } from "bootstrap-vue";
  import axios from "axios";
  import FlagComponent from "./components/FlagComponent.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import UserCurrentPlan from "./Models/UserCurrentPlan.vue";
  import appUsersGemsHistory from "../views/Models/appUsersGemsHistory.vue";
  import * as Helpers from "../Helpers";
  import DateRangePicker from "vue2-daterange-picker";
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import vSelect from 'vue-select';
  import moment from 'moment'
  export default {
    components: {
      FlagComponent,
      BOverlay,
      BFormCheckbox,
      BCard,
      BRow,
      ToastificationContent,
      BCol,
      BTable,
      BLink,
      BTableSimple,
      BThead,
      BTr,
      BTh,
      BFormInput,
      BTd,
      BTbody,
      BTfoot,
      BFormTextarea,
      BPagination,
      UserCurrentPlan,
      appUsersGemsHistory,
      DateRangePicker,
      vSelect,
      BAlert,
      BFormGroup,
      BTabs,
      BTab
    },
    data() {
      return {
        table_data: [],
        searchQuery: "",
        searchCountry: "",
        show: false,
        removeButtons: "",
        table_: [],
        toastMsg: "",
        sidebarkey: 0,
        selectedProfileEmail: "",
        selectedId: "",
        showUserDetailsModal: false,
        showInstallReferrerSourceModal: false,
        notificationMessage: "",
        description: '',
        descriptionError: false,
        showdiscriptionModal: false,
        adminAccess: {},
        currentPage: 1,
        selected_user: {},
        get_plans: [],
        get_billing_cycles: [],
        get_countries: [],
        per_page: 0,
        Helpers,
        InstallReferrerSourceData: {},
        dateRange: {},
        isChangePasswordModel: false,
        password: "",
        confirmPassword: "",
        errorMsg: "",
        selectedUserId: "",
        isDisabledButton: false,
        plansWithCountry: [],
        selectedPlan: "",
        planName: "",
        countryCode: "",
        subscriptionInfo:"",
        isLoadingSubscription:true,
        releaseTypes:['Test','Live'],
        platformsData:['Android','Ios'],
        selectedPlatform:null,
        get_currencies:[],
        sidebarkey:0,
        selectedType:'Test'
      };
    },
    computed: {
      hasError() {
        return this.errorMsg != "";
      },
  
  
      CustomFontSize() {
        let fontSize = '14px';
        if (this.selectedPlan) {
          const textLength = this.selectedPlan.length;
          if (textLength > 25) {
            fontSize = '12px';
          }
        }
        return {
          'font-size': fontSize
        };
      },
  
      CustomFontSizeForCountry() {
        let fontSize = '14px';
        if (this.searchCountry) {
          let code = this.searchCountry;
          if (code) {
            let name = this.getCountryNameByCode(code);
            if (name) {
              const textLength = name.length;
              if (textLength > 25) {
                fontSize = '10px';
              }
            }
          }
        }
  
        return {
          'font-size': fontSize
        };
      }
  
  
    },
    mounted() {
      const permissions = JSON.parse(localStorage.getItem("permission"));
      this.removeButtons = permissions?.permission?.includes("manage_app_users") || false;
    },
    created() {
      this.dateRange = {
        startDate: '',
        endDate: '',
      };
      this.getTableData();
      this.getPlans();
    },
  
    watch: {
      selectedPlan: function (newVal) {
        if (!newVal) {
          this.planName = "",
            this.countryCode = "",
            this.getTableData(1);
            this.getPlans();
  
        }
      }
    },
    methods: {
      getCountryNameByCode(countryCode) {
        if (countryCode && this.get_countries.length > 0) {
          const country = this.get_countries.find(country => country.code === countryCode);
          return country ? country.name : 'Country not found';
        }
  
      },
      getDateISO(data){
        if (data) {
          
          const timestamp = Number(data.$date.$numberLong);
          const date = new Date(timestamp);
         return moment(date).format('MM/DD/YYYY HH:mm A')
    
        }
      },
      formatMilli(second){
        const date = new Date(second);
        return date.toLocaleString();
      },
      getPlans: function (page = 1) {
        var url = process.env.VUE_APP_API_URL + `/billing/plans?page=${page}`;
        const payload = this.record_filters
        this.show = true;
        axios
          .post(url, payload, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((res) => {
            this.get_currencies = res.data.currencies;
          })
          .catch(function (error) {
            console.log("error", error);
          });
  
        },
      separatePlanAndCountry() {
        let input = this.selectedPlan;
        const match = input.match(/^(.+?)\s*\(([^)]+)\)$/);
  
        if (match) {
          this.planName = match[1].trim();
          this.countryCode = match[2].trim()
        } else {
          this.planName = '';
          this.countryCode = ''
        }
      },
  
  
      filterByPlan(plan) {
        this.getTableData(1);
      },
      filterByPlatform() {
        this.getTableData(1);
      },
      filterByOrder() {
        this.getTableData(1);
      },
      async submitChangePassword() {
        if (this.isDisabledButton) {
          return;
        }
        this.isDisabledButton = true;
        let status = this.checkValidation();
        if (!status) {
          this.isDisabledButton = false;
          return;
        }
        try {
          let payload = {
            password: this.password,
            description: this.description,
            userId: this.selectedUserId
          }
          const resp = await axios.put(process.env.VUE_APP_API_URL + "/user/password/update",
            payload,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            });
  
          if (resp.data.message) {
            this.alertMessageToast = resp.data.message;
            this.showToast("success");
            this.cancelChangePassword();
            this.isDisabledButton = false;
          }
        } catch (error) {
          if (error.response) {
            this.errorMsg = error.response.data.error || 'An error occurred';
          } else if (error.request) {
            this.errorMsg = 'No response received from server. Please check your network connection.';
          } else {
            this.errorMsg = 'Error in setting up the request: ' + error.message;
          }
          this.loading = false;
          this.alertMessageToast = this.errorMsg;
          this.isDisabledButton = false;
          this.showToast("danger");
        }
      },
      clearErrorMsg() {
        this.errorMsg = "";
      },
      checkValidation() {
        if (!this.password.length) {
          this.errorMsg = "Password required."
          return;
        }
  
        if (this.password.length < 8) {
          this.errorMsg = "Password atleast 8 character."
          return;
        }
  
        if (!this.confirmPassword.length) {
          this.errorMsg = "Confirm Password required."
          return;
        }
  
        if (this.password != this.confirmPassword) {
          this.errorMsg = "Confirm Password not match."
          return;
        }
  
        if (this.description.length < 20) {
          this.errorMsg = "Please enter at least 20 characters for Description."
          return;
        }
  
        return "ok";
      },
      showChnagePasswordModel(userId) {
        this.selectedUserId = userId;
        this.isChangePasswordModel = true;
      },
      cancelChangePassword() {
        this.description = '';
        this.password = '';
        this.confirmPassword = '';
        this.isChangePasswordModel = false;
      },
      confirmStatusChange(object) {
        this.showdiscriptionModal = true;
        this.adminAccess = object;
      },
      cancelModal() {
        this.showdiscriptionModal = false;
        this.adminAccess.adminAccess = !this.adminAccess.adminAccess;
        this.descriptionError = false;
        this.description = '';
      },
      submitDescription() {
        if (this.description.length < 50) {
          this.descriptionError = true;
        } else {
          this.descriptionError = false;
          const payload = {
            ...this.adminAccess,
            description: this.description
          };
          this.statusChangeAdminAccess(payload);
          this.showdiscriptionModal = false;
          this.description = ''
        }
      },
      showToasted(variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: this.notificationMessage,
            variant,
          },
        });
      },
      showUserDetails(profile) {
        let id = typeof profile._id === 'string' ? profile._id : profile._id.$oid;
  
        axios.post(process.env.VUE_APP_API_URL + "/app-user/email/log", {
          userId: id
        }, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
          .then(response => {
            this.selectedProfileEmail = response.data.email;
            this.selectedId = response.data.Id
            this.showUserDetailsModal = true;
          })
          .catch(error => {
            if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
              this.notificationMessage = 'Request timed out. Please try again later.';
            } else if (error.response) {
              this.notificationMessage = error.response.data.message;
            } else {
              this.notificationMessage = 'An error occurred. Please try again later.';
            }
            console.log('error=', error.response)
            this.showToasted("danger");
            this.show = false;
          });
      },
      showInstallReferrerSource(data) {
        this.showInstallReferrerSourceModal = true;
        this.InstallReferrerSourceData = data;
      },
      formatDate(dateObj) {
        if (dateObj && dateObj['$date'] && dateObj['$date']['$numberLong']) {
          const timestamp = parseInt(dateObj['$date']['$numberLong']);
          const date = new Date(timestamp);
          const options = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short' // Include time zone name
          };
          return date.toLocaleString('en-GB', options); // Adjust locale as needed
        } else {
          return '-';
        }
      },
      filterAppUser() {
        this.show = true;
        setTimeout(() => {
          this.getTableData(1, this.searchQuery);
          this.show = false;
        }, 1000);
      },
      forcRender() {
        this.sidebarkey += 1;
      },
      statusChangeAdminAccess(object) {
        this.show = true;
        axios
          .post(process.env.VUE_APP_API_URL + "/app-user/change/status", object, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            if (get_response.data.status) {
              this.toastMsg = get_response.data.message;
              this.showToast("success");
              this.show = false;
            } else {
              this.toastMsg = "There are some problem try again";
              this.showToast("danger");
              this.show = false;
            }
          })
          .catch(function (error) {
            console.log("error", error);
          });
      },
      showToast(variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: this.toastMsg,
            variant,
          },
        });
      },
  
      getPlanByCountry(plans) {
  
        if (plans) {
         
          this.plansWithCountry = plans.map(plan => ({
            id: plan._id,
            name: `${plan.name} (${plan.country_code})`
          }));
          this.plansWithCountry.unshift({
          id: '0',
          name: 'Free Plan'
        });
        } else {
          console.log('No plans found');
        }
      },
  
      clearDateRange() {
        this.dateRange = {
          startDate: null,
          endDate: null,
        };
        this.getTableData();
      },
      filterByCountryUsers(country) {
        console.log('filterByDateUsers country=', country);
        this.getTableData(1);
      },
      filterByDateUsers(date) {
        console.log('filterByDateUsers date=', date);
        this.getTableData(1);
      },
      getTableData: function (page = 1) {
  
        var url = process.env.VUE_APP_API_URL + `/order/all?page=${page}`;
        const payload = {}
        payload.search = this.searchQuery;
        payload.country = this.searchCountry;
        payload.startDate = this.dateRange.startDate ? Helpers.changeDateFormat(this.dateRange.startDate) : null;
        payload.endDate = this.dateRange.endDate ? Helpers.changeDateFormat(this.dateRange.endDate) : null;
        payload.plan = this.selectedPlan ?  this.selectedPlan.id : null;
        payload.platform = this.selectedPlatform;
        payload.type = this.selectedType;
        this.show = true;
  
        axios
          .post(url, payload, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            this.get_plans = get_response.data.billing_plans;
            this.get_billing_cycles = get_response.data.billing_cycles;
            this.get_countries = get_response.data.countries;
            let response = get_response.data.accounts;
            // for (let index = 0; index < response.length; index++) {
            //   for (let k = 0; k < response[index].profile.length; k++) {
            //     if (!response[index].profile[k]["adminAccess"]) {
            //       response[index].profile[k]["adminAccess"] = false;
            //     }
            //   }
            // }
            this.table_ = response;
            this.table_data = response;
            this.show = false;
            // this.per_page = response.per_page;
            // this.selected_user = {};
            if (get_response.data.billing_plans) {
              this.getPlanByCountry(get_response.data.billing_plans);
            }
          })
          .catch(function (error) {
            console.log("error", error);
          });
      },
      editUserPlanBtn(userData,platform) {
        this.selected_user = userData;
        this.selected_user['platform'] = platform;
        if (userData.current_plan || userData.subscriptionExpiredDateTime) {
          const  status= this.checkSubscriptionExpired(userData.subscriptionExpiredDateTime);
          if (status == 'Active') { 
            this.$bvModal.show("delete-modal");
          }else{
          this.$bvModal.show("user-current-plan-modal");
          }
        }else{
          this.$bvModal.show("user-current-plan-modal");
        }
      },
      checkSubscriptionExpired(dateMillis) {
      if (dateMillis) {   
        const currentTimeMillis = Math.round(Date.now());
        const expiryTimeMillis = parseInt(dateMillis, 10);
    
        return expiryTimeMillis > currentTimeMillis ? 'Active' : 'Expired';
      }
      return "Expired";
    },
      handleDelete(){
        var url = process.env.VUE_APP_API_URL + `/app-user/cancelProfileSubscription`;
  
        axios
          .post(url, {register_user_id:this.selected_user._id,plan_id:this.selected_user.current_plan._id}, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }).then(response=>{
             if (response.data) {
              this.$refs['popup-modal'].hide();
              // this.$bvModal.show("delete-modal").hide();
              this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Subscription cancelled successfully",
                variant:'success'
              },
            });
  
              this.getTableData(1);
             }
          }).catch(err=>{
           this.isLoadingSubscription = false;
           console.log(err)
          
          })
      },
      getUserSubscription(){
        let url = process.env.VUE_APP_API_URL + `/app-user/subscription`;
        axios
          .post(url, {serialNumber:this.selected_user.serialNumber}, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }).then(response=>{
             if (response.data) {
              this.subscriptionInfo = response.data.subscriptionDetail;
              this.isLoadingSubscription = false;
  
             }
          }).catch(err=>{
           this.isLoadingSubscription = false;
           console.log(err)
          
          })
      },
      getSubscriptionInfo(userData) {
        this.isLoadingSubscription = true;
        this.selected_user = userData;
        this.getUserSubscription();
        this.$bvModal.show("subscription-modal");
      },
      resetSubscription() {
       this.selected_user = "";
      },
      getDate(date){
        return moment(Number(date)).format('MM/DD/YYYY HH:mm A');
      },
      convertMicrosToBaseCurrency(priceAmountMicros) {
       return priceAmountMicros / 1000000;
      }
  
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .invoice-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  
  .date-details-container {
    display: flex;
    flex-direction: column;
  }
  
  .small-details-link {
    font-size: 10px;
  }
  </style>